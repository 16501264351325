import React, { useEffect, useRef, useState } from 'react'
import styles from './navbar.module.scss'
import { ReactComponent as InfinityLogo } from '../../../assets/svg/infinity_logo.svg'
import { ReactComponent as ZirohLogo } from '../../../assets/svg/zirohLabs_logo_light.svg'
import { ReactComponent as Arrow } from '../../../assets/svg/arrow_head.svg'
import { Link } from 'react-router-dom'
import { BUTTON_THEMES, DEVICE } from '../../constants/constants'
import { useDeviceStore } from '../../../StateManager/DeviceStore'
import { AnimatePresence, motion, MotionConfig } from 'framer-motion'
import useGlobalHook from '../../hooks/global.hook'
import { ROUTES } from '../../constants/routes'
import CommonButton from '../CommonButton/commonButton.component'
import { Feature } from '../../types/navMenuData'
import NAV_MENU from './data/navmenuData'
import { OutsideClickHandler } from '../OutsideClickHandler/OutsideClickHandler'



function Navbar() {

    const { device } = useDeviceStore();
    const { disableScroll, enablePageScroll } = useGlobalHook();

    const [isPlatformsActive, setIsPlatformsActive] = useState(false)
    const [isResourcesActive, setIsResourcesActive] = useState(false)
    const [isNavActive, setIsNavActive] = useState(false)


    useEffect(() => {
        setIsNavActive(false)
        setIsPlatformsActive(false)
        setIsResourcesActive(false)
        document.body.style.overflow = 'unset';
    }, [device])


    useEffect(() => {
        if (isNavActive) {
            disableScroll()
        }
        else {
            setIsPlatformsActive(false)
            setIsResourcesActive(false)
            enablePageScroll()
        }
    }, [isNavActive])

    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = navRef.current;

        const handleResize = (entries: ResizeObserverEntry[]) => {
            for (let entry of entries) {
                const contentHeight = entry.contentRect.height; // Content height
                const styles = window.getComputedStyle(entry.target); // Get computed styles
                const paddingTop = parseFloat(styles.paddingTop);
                const paddingBottom = parseFloat(styles.paddingBottom);

                // Total height = content height + padding
                const totalHeight = contentHeight + paddingTop + paddingBottom;

                // Dynamically update the global CSS variable
                document.documentElement.style.setProperty("--nav_height", `${totalHeight}px`);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (element) {
            resizeObserver.observe(element);
        }

        // Cleanup on component unmount
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, []);


    function FeatDropdown(data: Feature[] | undefined) {
        if (!data) return null; // Return null if no data is provided

        return (
            <div className={styles.dropDown}>
                {data.map(({ title, icon: Icon, description, url }, index) => {
                    return (
                        <Link to={url} reloadDocument className={styles.feature} key={index}>
                            <Icon />
                            <div className={styles.text}>
                                <p className={styles.featName}>{title}</p>
                                <p className={styles.featDesc}>{description}</p>
                            </div>
                        </Link>
                    );
                })}
            </div>
        );
    }




    return (
        <OutsideClickHandler onOutsideClick={() => { setIsNavActive(false) }}>
        <nav className={`${styles.navbar}`} ref={navRef}>
            <div className={styles.navContents}>
                <Link to={ROUTES.HOME} reloadDocument className={styles.logoContainer}>
                    <InfinityLogo />
                </Link>

                {device !== DEVICE.MOBILE && device !== DEVICE.TABLET_POTRAIT &&
                    <div className={styles.navOptionsContainer}>
                        <div className={styles.navOption} onMouseEnter={() => { setIsPlatformsActive(true) }} onMouseLeave={() => { setIsPlatformsActive(false) }}>
                            <CommonButton label={NAV_MENU.PLATFORMS[0].title} theme={BUTTON_THEMES.TRANSPARENT} buttonSize={'small'} icon={Arrow} iconRotateOnHover />
                            <AnimatePresence mode="wait">
                                {isPlatformsActive &&
                                    <motion.div
                                        key="navOptions"
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{
                                            duration: .3,
                                            ease: "linear",
                                        }}
                                        className={styles.navOptions}
                                    >
                                        {FeatDropdown(NAV_MENU.PLATFORMS[0]?.FEATURES)}
                                    </motion.div>

                                }
                            </AnimatePresence>
                        </div>
                        <div className={styles.navOption} onMouseEnter={() => { setIsResourcesActive(true) }} onMouseLeave={() => { setIsResourcesActive(false) }}>
                            <CommonButton label={NAV_MENU.RESOURCES[0].title} theme={BUTTON_THEMES.TRANSPARENT} buttonSize={'small'} icon={Arrow} iconRotateOnHover />
                            <AnimatePresence mode="wait">
                                {isResourcesActive &&
                                    <motion.div
                                        key="navOptions"
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{
                                            duration: .3,
                                            ease: "linear",
                                        }}
                                        className={styles.navOptions}
                                    >
                                        {FeatDropdown(NAV_MENU.RESOURCES[0]?.FEATURES)}
                                    </motion.div>

                                }
                            </AnimatePresence>
                        </div>
                        <Link to="" className={styles.navOption} reloadDocument>
                            <CommonButton label={NAV_MENU.ABOUT_US[0].title} theme={BUTTON_THEMES.TRANSPARENT} buttonSize={'small'} />
                        </Link>
                        <Link to={""} className={styles.navOption} reloadDocument>
                            <CommonButton label={NAV_MENU.CONTACT_US[0].title} theme={BUTTON_THEMES.TRANSPARENT} buttonSize={'small'} />
                        </Link>
                    </div>
                }

                <div className={styles.ctaContainer}>

                    <Link to={""} reloadDocument className={styles.cta}>
                        <CommonButton label='Book a Demo' theme={BUTTON_THEMES.PRIMARY} buttonSize='medium' />
                    </Link>

                    {(device === DEVICE.MOBILE || device === DEVICE.TABLET_POTRAIT) &&
                        <div className={styles.hamburger} onClick={() => { setIsNavActive(!isNavActive) }}>
                            <span className={`${styles.burger} ${isNavActive && `${styles.activeBurger1}`}`}></span>
                            <span className={`${styles.burger} ${isNavActive && `${styles.activeBurger2}`}`}></span>
                            {!isNavActive &&
                                <span className={`${styles.burger} ${isNavActive && `${styles.activeBurger3}`}`}></span>
                            }
                        </div>
                    }
                </div>


                <AnimatePresence mode="wait">
                    {isNavActive &&
                            <motion.div
                                key="navOptions"
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{
                                    duration: .3,
                                    ease: "linear",
                                }}
                                className={styles.dropdownMobile}
                            >
                                <div className={`${styles.categoryWrapper} ${isPlatformsActive && `${styles.activeCategory}`}`}>
                                    <div className={styles.titleWrapper} onClick={() => setIsPlatformsActive(!isPlatformsActive)}>
                                        <p className={styles.title}>{NAV_MENU.PLATFORMS[0].title}</p>
                                        <Arrow />
                                    </div>

                                    <AnimatePresence mode='wait'>
                                        {isPlatformsActive &&
                                            <motion.div
                                                key={"navOptionDropdown"}
                                                initial={{ height: 0 }}
                                                animate={{ height: "auto" }}
                                                exit={{ height: 0 }}
                                                transition={{
                                                    duration: .3,
                                                    ease: "linear",
                                                }}
                                                className={styles.categoryFeatsWrapper}
                                            >
                                                {NAV_MENU.PLATFORMS[0].FEATURES?.map(({ title, icon: Icon, description, url }, index) => {
                                                    return (
                                                        <Link to={url} reloadDocument className={styles.feature} key={index}>
                                                            <Icon />
                                                            <div className={styles.text}>
                                                                <p className={styles.featName}>{title}</p>
                                                                <p className={styles.featDesc}>{description}</p>
                                                            </div>
                                                        </Link>
                                                    )
                                                })}
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                </div>

                                <div className={`${styles.categoryWrapper} ${isResourcesActive && `${styles.activeCategory}`}`}>
                                    <div className={styles.titleWrapper} onClick={() => setIsResourcesActive(!isResourcesActive)}>
                                        <p className={styles.title}>{NAV_MENU.RESOURCES[0].title}</p>
                                        <Arrow />
                                    </div>

                                    <AnimatePresence mode='wait'>
                                        {isResourcesActive &&
                                            <motion.div
                                                key={"navOptionDropdown"}
                                                initial={{ height: 0 }}
                                                animate={{ height: "auto" }}
                                                exit={{ height: 0 }}
                                                transition={{
                                                    duration: .3,
                                                    ease: "linear",
                                                }}
                                                className={styles.categoryFeatsWrapper}
                                            >
                                                {NAV_MENU.RESOURCES[0].FEATURES?.map(({ title, icon: Icon, description, url }, index) => {
                                                    return (
                                                        <Link to={url} reloadDocument className={styles.feature} key={index}>
                                                            <Icon />
                                                            <div className={styles.text}>
                                                                <p className={styles.featName}>{title}</p>
                                                                <p className={styles.featDesc}>{description}</p>
                                                            </div>
                                                        </Link>
                                                    )
                                                })}
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                </div>

                                <Link to={NAV_MENU.CONTACT_US[0].url || '/'} reloadDocument className={`${styles.categoryWrapper}`}>
                                    <div className={styles.titleWrapper}>
                                        <p className={styles.title}>{NAV_MENU.ABOUT_US[0].title}</p>
                                    </div>
                                </Link>

                                <Link to={NAV_MENU.CONTACT_US[0].url || '/'} reloadDocument className={`${styles.categoryWrapper}`}>
                                    <div className={styles.titleWrapper}>
                                        <p className={styles.title}>{NAV_MENU.CONTACT_US[0].title}</p>
                                    </div>
                                </Link>

                            </motion.div>
                    }
                </AnimatePresence>

            </div>
        </nav>
        </OutsideClickHandler>
    )
}

export { Navbar }