import React from 'react';
import { CommonButtonProps } from '../../types/commonButtonProps';
import styles from './commonButton.module.scss';

const CommonButton: React.FC<CommonButtonProps> = ({
  label,
  theme = '', // Default to an empty string if no theme is provided
  isDisabled = false,
  icon: Icon,
  clickHandler,
  buttonType = 'button', // Default button type
  isLoading = false,
  buttonSize,
  iconRotateOnHover,
  ...props // Capture additional props
}) => {
  return (
    <button
      type={buttonType}
      className={`
        ${styles.button} 
        ${styles[theme] || ''} 
        ${styles[buttonSize] || ''}
        ${Icon && `${styles.iconPadding}`}
        ${iconRotateOnHover && `${styles.iconHover}`}
      `}
      onClick={clickHandler || props.onClick} // Prioritize custom handler
      disabled={isDisabled}
      {...props} // Forward remaining props
    >
      {!isLoading && label}
      {Icon && !isLoading && <Icon className={styles.icon} />}
      {isLoading && (
        <span className={styles.button_loader}>
          <span>&bull;</span>
          <span>&bull;</span>
          <span>&bull;</span>
        </span>
      )}
    </button>
  );
};

export default CommonButton;
