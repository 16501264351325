import React, { useEffect, useRef, useState } from 'react'
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { useOsStore } from '../../StateManager/OsStore';
import { BUTTON_THEMES, DEVICE } from '../../_shared/constants/constants';
import styles from './deploy.module.scss';
import "react-multi-carousel/lib/styles.css";
import useGlobalHook from '../../_shared/hooks/global.hook';
import Hero_img from '../../assets/webp/hero_img.webp';
import CommonButton from '../../_shared/components/CommonButton/commonButton.component';
import { ReactComponent as DUMMY_ICON } from '../../assets/svg/model_lib_feat3.svg';
import { Link } from 'react-router-dom';


function Deploy() {
    const { device } = useDeviceStore()
    const { os } = useOsStore()
    const { disableScroll, enablePageScroll } = useGlobalHook();


    return (
        <div className={styles.home}>

            <div className={styles.heroSection}>

                <div className={styles.textContainer}>
                    <p className={styles.title}>Run Your Edge AI App Locally or In the Cloud</p>
                    <p className={styles.description}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>

                    {device === DEVICE.MOBILE &&
                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="hero" />
                        </div>
                    }

                    <div className={styles.buttonsContiner}>
                        <Link to="" reloadDocument className={styles.btnWrapper}>
                            <CommonButton label='Book a Demo' theme={BUTTON_THEMES.PRIMARY} buttonSize='xlarge' />
                        </Link>
                        <Link to="" reloadDocument className={styles.btnWrapper}>
                            <CommonButton label='Learn More' theme={BUTTON_THEMES.LIGHT} buttonSize='xlarge' />
                        </Link>
                    </div>
                </div>

                {device !== DEVICE.MOBILE &&
                    <div className={styles.imgContainer}>
                        <img src={Hero_img} alt="hero" />
                    </div>
                }
            </div>

            <div className={styles.featuresSection}>

                <div className={styles.feat}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div className={styles.iconWrapper}>
                                <DUMMY_ICON />
                            </div>
                            <p className={styles.title}>Lorem Ipsum is simply dummy text</p>
                            <p className={styles.description}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                            {device !== DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>

                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="feat" />
                            {device === DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.feat}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div className={styles.iconWrapper}>
                                <DUMMY_ICON />
                            </div>
                            <p className={styles.title}>By AI CPU Cloud</p>
                            <p className={styles.description}>You can deploy on low memory devices with internet connections.  Zunu automatically connects to AI CPU Cloud to run your inferences.</p>
                            {device !== DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>

                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="feat" />
                            {device === DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.feat}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div className={styles.iconWrapper}>
                                <DUMMY_ICON />
                            </div>
                            <p className={styles.title}>By Device</p>
                            <p className={styles.description}>A runtime is downloaded for the  edge device you have selected.  Infinity Runtime handles device compatibility and optimized execution.</p>
                            {device !== DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>

                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="feat" />
                            {device === DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export { Deploy }