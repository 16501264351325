import React from 'react'
import styles from './terms.module.scss'

import { lazily } from 'react-lazily';
const { PrivacyPolicyContents } = lazily(() => import('../../_shared/components/PolicyContents/privacyPolicyContents'));


function Terms() {
  return (
    <div className={styles.policyPage}>
        <p className={styles.title}>Terms of Service</p>

        <PrivacyPolicyContents showContentFor={'terms'} />
    </div>
  )
}

export default Terms
