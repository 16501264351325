import React from 'react'
import styles from './policy.module.scss'

import { lazily } from 'react-lazily';
const { PrivacyPolicyContents } = lazily(() => import('../../_shared/components/PolicyContents/privacyPolicyContents'));


function Policy() {
  return (
    <div className={styles.policyPage}>
        <p className={styles.title}>Privacy Policy: Zunu Infinity Edge Platform</p>

        <PrivacyPolicyContents showContentFor={'policy'} />
    </div>
  )
}

export default Policy
