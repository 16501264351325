import React, { useEffect, useState } from 'react';
import { DEVICE, PLATFORM } from './_shared/constants/constants';
import { useDeviceStore } from './StateManager/DeviceStore';
import { useOsStore } from './StateManager/OsStore';


const useAppHook = () => {
    const { os, setOs } = useOsStore();
    const { device, setDevice } = useDeviceStore();


    function handleResize() {
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if(window.innerWidth >730 && window.innerWidth <1000 ){
            setDevice(DEVICE.TABLET_POTRAIT)
        }
        else if(window.innerWidth >1000 && window.innerWidth <1200){
            setDevice(DEVICE.TABLET_LANDSCAPE)
        }
        else if (window.innerWidth >1200 && window.innerWidth <1700) {
            setDevice(DEVICE.DESKTOP)
        }
        else if(window.innerWidth > 1700){
            setDevice(DEVICE.DESKTOP_LARGE)
        }
    }


    useEffect(() => {
        window.addEventListener('resize', handleResize)
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if(window.innerWidth >730 && window.innerWidth <1000 ){
            setDevice(DEVICE.TABLET_POTRAIT)
        }
        else if(window.innerWidth >1000 && window.innerWidth <1200){
            setDevice(DEVICE.TABLET_LANDSCAPE)
        }
        else if (window.innerWidth >1200 && window.innerWidth <1700) {
            setDevice(DEVICE.DESKTOP)
        }
        else if(window.innerWidth > 1700){
            setDevice(DEVICE.DESKTOP_LARGE)
        }

        getOS()

    }, [])


    const getOS = (): any => {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;

        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const androidPlatforms = ['Android'];

        // setOs(window.navigator.userAgent)
        if (windowsPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.WIN)
            return 'Windows';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width > 1400) {
            setOs(PLATFORM.MAC)
            return 'mac';
        }
        else if (iosPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.IOS)
            return 'iOS';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width < 1200) {
            setOs(PLATFORM.IOS)
            return 'iPadOS';
        }
        else if (userAgent.indexOf('Android') !== -1) {
            setOs(PLATFORM.AND)
            return 'android'
        }
        else if (/Linux/.test(userAgent)) {
            setOs("linux")
            return 'Linux';
        }

        setOs(PLATFORM.WIN)
        return 'Unknown OS';
    };

    return {
        os, setOs,
        getOS,
    }
}

export default useAppHook;