import React, { useEffect, useRef, useState } from 'react'
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { useOsStore } from '../../StateManager/OsStore';
import { BUTTON_THEMES, DEVICE } from '../../_shared/constants/constants';
import styles from './home.module.scss';
import "react-multi-carousel/lib/styles.css";
import useGlobalHook from '../../_shared/hooks/global.hook';
import { OutsideClickHandler } from '../../_shared/components/OutsideClickHandler/OutsideClickHandler';
import Hero_img from '../../assets/webp/hero_img.webp';
import Platform from '../../assets/webp/infinityPlatform.webp';
import Benchmark1 from '../../assets/webp/benchmark_result1.webp';
import Benchmark2 from '../../assets/webp/benchmark_result2.webp';
import SplitArrows from '../../assets/webp/split_arrows.webp';
import { ReactComponent as FeatIcon1 } from '../../assets/svg/model_lib_feat1.svg';
import { ReactComponent as FeatIcon2 } from '../../assets/svg/model_lib_feat2.svg';
import { ReactComponent as FeatIcon3 } from '../../assets/svg/model_lib_feat3.svg';
import CommonButton from '../../_shared/components/CommonButton/commonButton.component';
import { Link } from 'react-router-dom';


function Home() {
    const { device } = useDeviceStore()
    const { os } = useOsStore()
    const { disableScroll, enablePageScroll } = useGlobalHook();


    return (
        <div className={styles.home}>

            <div className={styles.heroSection}>

                <div className={styles.textContainer}>
                    <p className={styles.title}>CPUs are your friends in AI development</p>
                    <p className={styles.description}>Zunu can deploy your AI apps on various CPU and CPU/GPU devices.  You can also easily access on-demand CPU cloud resourcess to customize your app.</p>

                    {device === DEVICE.MOBILE &&
                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="hero" />
                        </div>
                    }

                    <div className={styles.buttonsContiner}>
                        <Link to="" reloadDocument className={styles.btnWrapper}>
                            <CommonButton label='Book a Demo' theme={BUTTON_THEMES.PRIMARY} buttonSize='xlarge' />
                        </Link>
                        <Link to="" reloadDocument className={styles.btnWrapper}>
                            <CommonButton label='Learn More' theme={BUTTON_THEMES.LIGHT} buttonSize='xlarge' />
                        </Link>
                    </div>
                </div>

                {device !== DEVICE.MOBILE &&
                    <div className={styles.imgContainer}>
                        <img src={Hero_img} alt="hero" />
                    </div>
                }
            </div>

            <div className={styles.stepsSection}>

                <div className={styles.contentWrapper}>
                    <p className={styles.title}>4 Easy Steps to Build Your AI App for Any Device</p>
                    <p className={styles.description}>Zunu Infinity simplifies AI integration, providing developers of all levels with the tools to build more innovative applications with ease.</p>

                    <div className={styles.imgContainer}>
                        <img src={Platform} alt="platform" />
                    </div>
                </div>
            </div>

            <div className={styles.resultsSection}>

                <div className={styles.contentWrapper}>
                    <p className={styles.title}>AI Mathematics Can Run on CPUs</p>
                    <p className={styles.description}>AI models can now run on CPUs or CPU/GPU hybrids, providing you access to more edge devices and resourcess to customize your model.  AI on CPU outperforms GPUs in both performance and cost.</p>

                    <div className={styles.imgContainer}>
                        <img src={Benchmark1} alt="Benchmark" />
                        <img src={Benchmark2} alt="Benchmark" />
                    </div>
                </div>

            </div>

            <div className={styles.featuresSection}>

                <div className={styles.contentWrapper}>
                    <p className={styles.title}>Model Library</p>
                    <p className={styles.description}>Access hundreds of pre-built models for a diverse set of use cases, including text, voice, video, and image processing.</p>

                    {device !== DEVICE.MOBILE &&
                        <img src={SplitArrows} alt="arrows" className={styles.splitArrows} />
                    }

                    <div className={styles.cardsContainer}>
                        <div className={styles.card}>
                            <div className={styles.iconWrapper}>
                                <FeatIcon1 />
                            </div>
                            <p className={styles.title}>My Models</p>
                            <p className={styles.description}>Your centralized collection of models in use</p>
                        </div>

                        <div className={styles.card}>
                            <div className={styles.iconWrapper}>
                                <FeatIcon2 />
                            </div>
                            <p className={styles.title}>Search by Task</p>
                            <p className={styles.description}>Find models that are optimized for specific tasks</p>
                        </div>

                        <div className={styles.card}>
                            <div className={styles.iconWrapper}>
                                <FeatIcon3 />
                            </div>
                            <p className={styles.title}>Search by Target Devices</p>
                            <p className={styles.description}>See models that are optimized for the devices selected</p>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}

export { Home }