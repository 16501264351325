import React, { useEffect, useRef, useState } from 'react'
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { useOsStore } from '../../StateManager/OsStore';
import { BUTTON_THEMES, DEVICE } from '../../_shared/constants/constants';
import styles from './playground.module.scss';
import "react-multi-carousel/lib/styles.css";
import useGlobalHook from '../../_shared/hooks/global.hook';
import Hero_img from '../../assets/webp/hero_img.webp';
import CommonButton from '../../_shared/components/CommonButton/commonButton.component';
import { ReactComponent as DUMMY_ICON } from '../../assets/svg/model_lib_feat3.svg';
import { Link } from 'react-router-dom';


function Playground() {
    const { device } = useDeviceStore()
    const { os } = useOsStore()
    const { disableScroll, enablePageScroll } = useGlobalHook();


    return (
        <div className={styles.home}>

            <div className={styles.heroSection}>

                <div className={styles.textContainer}>
                    <p className={styles.title}>Evaluate Your Model</p>
                    <p className={styles.description}>Test your selected model with prompts and view key statistics to get a complete picture before downloading or using it for inference.</p>

                    {device === DEVICE.MOBILE &&
                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="hero" />
                        </div>
                    }

                    <div className={styles.buttonsContiner}>
                        <Link to="" reloadDocument className={styles.btnWrapper}>
                            <CommonButton label='Book a Demo' theme={BUTTON_THEMES.PRIMARY} buttonSize='xlarge' />
                        </Link>
                        <Link to="" reloadDocument className={styles.btnWrapper}>
                            <CommonButton label='Learn More' theme={BUTTON_THEMES.LIGHT} buttonSize='xlarge' />
                        </Link>
                    </div>
                </div>

                {device !== DEVICE.MOBILE &&
                    <div className={styles.imgContainer}>
                        <img src={Hero_img} alt="hero" />
                    </div>
                }
            </div>

            <div className={styles.featuresSection}>

                <div className={styles.feat}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div className={styles.iconWrapper}>
                                <DUMMY_ICON />
                            </div>
                            <p className={styles.title}>By Model Type </p>
                            <p className={styles.description}>Choose the most appropriate version (base, quantized, approximated, compressed, distilled) for your specific use case, optimizing both performance and resource utilization.</p>
                            {device !== DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>

                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="feat" />
                            {device === DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.feat}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div className={styles.iconWrapper}>
                                <DUMMY_ICON />
                            </div>
                            <p className={styles.title}>By Machine Type</p>
                            <p className={styles.description}>Choose a machine for testing and see varying quantitative results.  For example, Intel 96-core machine will process a significantly higher number of tokens than a 24-core machine.</p>
                            {device !== DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>

                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="feat" />
                            {device === DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.feat}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.textContainer}>
                            <div className={styles.iconWrapper}>
                                <DUMMY_ICON />
                            </div>
                            <p className={styles.title}>By Geography to Adhere to Privacy Regulations</p>
                            <p className={styles.description}>Choose the right geographic location for testing. When testing with actual data or data containing personally identifiable Information (PII), it is essential that the testing takes place within specific geographic regions.</p>
                            {device !== DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>

                        <div className={styles.imgContainer}>
                            <img src={Hero_img} alt="feat" />
                            {device === DEVICE.MOBILE &&
                                <Link to={''} reloadDocument className={styles.btnWrapper}>
                                    <CommonButton label='Learn More' buttonSize='xlarge' theme={BUTTON_THEMES.LIGHT} />
                                </Link>
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export { Playground }