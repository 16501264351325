export const ROUTES = {
    HOME: '/',
    WHY_BUILD_WITH_INFINITY: '/why-build-with-infinity',
    INFERENCE_MODEL_BUILDING: '/inference-model-building',
    RESOURCES: '/resources',
    PLATFORMS: '/platforms',
    GITBOOK_URL: 'https://gitbook.ziroh.com/zunu-infinity',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_CONDITIONS: '/terms-of-service',

    MODEL_LIBRARY: '/platforms/model-library',
    PLAYGROUND: '/platforms/playground',
    DEPLOY: '/platforms/deploy',
    CUSTOMIZE: '/platforms/customize',



    SOCIAL_MEDIA:{
        LINKEDIN: 'https://www.linkedin.com/company/zirohlabs/',
        LINKEDIN_BUSINESS: 'http://linkedin.com/showcase/zunuforbusiness',
        FACEBOOK: 'https://www.facebook.com/zirohlabs/',
        X: 'https://x.com/zirohlabs',
        INSTAGRAM: 'https://www.instagram.com/zirohlabs/',
        YOUTUBE: 'https://www.youtube.com/@zirohlabs',
    },
}