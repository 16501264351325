import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import Layout from './Layout/layout';
import { Customize } from './Pages/Customize/customize';
import { Deploy } from './Pages/Deploy/deploy';
import { Home } from './Pages/Home/home';
import InferenceModelBuilding from './Pages/InferenceModelBuilding/inferenceModelBuilding';
import { ModelLibrary } from './Pages/ModelLibrary/modelLibrary';
import NotFound from './Pages/NotFound/notfound';
import { Playground } from './Pages/Playground/playground';
import Policy from './Pages/Policy/policy';
import Resources from './Pages/Resources/resources';
import Terms from './Pages/Terms/terms';
import WhyBuildWithInfinity from './Pages/WhyBuildWithInfinity/whyBuildWithInfinity';
import { ROUTES } from './_shared/constants/routes';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	const routes = AppRouter([
		{
			path: ROUTES.HOME,
			element: <Layout> </Layout>,

			children: [
				{
					path: ROUTES.HOME,
					element: <Home />
				},
				// {
				// 	path: ROUTES.WHY_BUILD_WITH_INFINITY,
				// 	element: <WhyBuildWithInfinity />
				// },
				// {
				// 	path: ROUTES.INFERENCE_MODEL_BUILDING,
				// 	element: <InferenceModelBuilding />
				// },
				// {
				// 	path: ROUTES.RESOURCES,
				// 	element: <Resources />
				// },
				{
					path: ROUTES.PLATFORMS,

					children: [
						{
							path: ROUTES.MODEL_LIBRARY,
							element: <ModelLibrary />
						},
						{
							path: ROUTES.PLAYGROUND,
							element: <Playground />
						},
						{
							path: ROUTES.CUSTOMIZE,
							element: <Customize />
						},
						{
							path: ROUTES.DEPLOY,
							element: <Deploy />
						},
					]
				},
				{
					path: ROUTES.PRIVACY_POLICY,
					element: <Policy />
				},
				{
					path: ROUTES.TERMS_CONDITIONS,
					element: <Terms />
				},
				{
					path: `/*`,
					element: <NotFound />
				}

			]

		},

	]);

	return <RouterProvider router={routes} />;
};

export default Routing;
