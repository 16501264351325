import { ReactComponent as ModelLibraryIcon } from '../../../../assets/svg/model_library_icon.svg';

import { ReactComponent as PlaygroundIcon } from '../../../../assets/svg/playground_icon.svg';

import { ReactComponent as DeployIcon } from '../../../../assets/svg/deploy_icon.svg';

import { ReactComponent as CustomizeIcon } from '../../../../assets/svg/customize_icon.svg';
import { NavMenu } from '../../../types/navMenuData';
import { ROUTES } from '../../../constants/routes';


const NAV_MENU: NavMenu = {
  PLATFORMS: [
    {
      title: 'Platform',
      FEATURES: [
        {
          title: 'Model Library',
          icon: ModelLibraryIcon,
          description: `Select from a diverse collection of pre-built text, speech and vision models`,
          url: ROUTES.MODEL_LIBRARY,
        },
        {
          title: 'Playground',
          icon: PlaygroundIcon,
          description: `Experiment with your AI apps`,
          url: ROUTES.PLAYGROUND,
        },
        {
          title: 'Customize',
          icon: CustomizeIcon,
          description: `Fine-tune your model with new datasets.`,
          url: ROUTES.CUSTOMIZE,
        },
        {
          title: 'Deploy',
          icon: DeployIcon,
          description: `Get your AI app running on your device or in the cloud`,
          url: ROUTES.DEPLOY,
        },
      ],
    },
  ],
  RESOURCES: [
    {
      title: 'Resources',
      FEATURES: [
        {
          title: 'Blogs',
          icon: ModelLibraryIcon,
          description: `Lorem Ipsum`,
          url: '',
        },
        {
          title: 'GitBook',
          icon: PlaygroundIcon,
          description: `Lorem Ipsum`,
          url: '',
        },
        {
          title: 'Use Cases',
          icon: CustomizeIcon,
          description: `Lorem Ipsum`,
          url: '',
        },
      ],
    },
  ],
  ABOUT_US: [
    {
      title: 'About Us',
      url: ROUTES.HOME,
    },
  ],
  CONTACT_US: [
    {
      title: 'Contact Us',
      url: ROUTES.HOME,
    },
  ],
};

export default NAV_MENU;
